import React from 'react';
import { graphql } from 'gatsby';

import Markdown from '../components/elements/Markdown';
import Seo from '../components/global/Seo';
import Layout from '../components/Layout';
import Hero from '../components/global/Hero';

export const singleEventPageQuery = graphql`
  query offerQuery($slug: String) {
    sanityOffer(slug: { current: { eq: $slug } }) {
      _id
      _key
      title
      subtitle
      description
      image {
        asset {
          url
          gatsbyImageData
        }
      }
    }
  }
`;

const OfferPage = ({ data }) => {
  const page = data.sanityOffer;
  return (
    <Layout>
      <Seo
        title={page.title}
        description={page.description}
        image={page.image.asset.url}
      />
      <Hero
        title={page.title}
        subtitle={page.subtitle}
        image={page.image}
        link="/contact"
      />
      <div className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-8 content">
              <Markdown description={page.description} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OfferPage;

import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { Link } from 'gatsby';
// import * as dayjs from 'dayjs';

import { Heading, ScrollButton, Button, Subtitle } from '../elements';

const Section = styled.section`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(${(props) => props.image});
  background-position: center;
  background-size: cover;

  @media screen and (min-width: 1407px) {
    padding: 15rem 4.5rem !important;
  }
`;

const GlobalHero = (props) => {
  const { title, description, image, link, subtitle } = props;
  return (
    <Section
      className="section is-medium"
      image={image.asset && image.asset.url}
    >
      <div classnames="container">
        <div className="hero-body ">
          <div className="columns is-centered has-text-centered">
            <div className="column is-centered is-9">
              <Heading className="mb-4">{title}</Heading>
              <Subtitle className="mb-4"> {subtitle} </Subtitle>

              {description && (
                <ReactMarkdown
                  source={description}
                  className="has-text-white text-base has-text-weight-medium leading-9 mt-4"
                />
              )}
              {link && (
                <Link to={link.slug ? link.slug.current : '/contact'}>
                  <Button className="mt-4 is-medium">Learn more</Button>
                </Link>
              )}

              {/* {data.startAt && (
                <time
                  className="has-text-white text-2xl"
                  dateTime={dayjs(data.startAt).format('DD MMMM YYYY')}
                >
                  {dayjs(data.startAt).format('DD MMMM YYYY')}
                </time>
              )} */}
              <ScrollButton />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default GlobalHero;
